'use client'

import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import trackImpression from 'app/components/Snowplow/trackImpression'
import { ISnowplowImpressionEvent } from 'app/components/Snowplow/Snowplow.interface'

interface ITrackingData
  extends Omit<
    ISnowplowImpressionEvent,
    'eventHorizontalPosition' | 'eventVerticalPosition'
  > {}

export interface ITrackImpressionProps extends ITrackingData {
  children?: React.ReactNode
  className?: string
  as?: string
  onFireEvent?: () => void
}

export function TrackImpression({
  as = 'div',
  onFireEvent,
  children,
  className,
  ...props
}: ITrackImpressionProps) {
  const [ref, inView, entry] = useInView({ triggerOnce: true, threshold: 0 })

  useEffect(() => {
    if (inView) {
      const viewportOffset = entry?.target?.getBoundingClientRect()
      trackImpression({
        ...props,
        eventHorizontalPosition: viewportOffset?.left || window.scrollX,
        eventVerticalPosition: viewportOffset?.top || window.scrollY,
        positionInUnit: props?.positionInUnit,
      })
      onFireEvent?.()
    }
  }, [inView])

  if (as === 'fragment') {
    return (
      <>
        {children}
        <span ref={ref} />
      </>
    )
  }

  return React.createElement(
    as,
    {
      className,
      ref,
    },
    children
  )
}

export default TrackImpression
