import { GLOBAL_CONTEXT_ID, IGlobalContext } from './types'

const emptyContext: Omit<IGlobalContext, 'locale'> = {
  slug: '',
  slugEn: null,
  slugEs: null,
  contentId: null,
  contentTypeId: null,
}

export default function getGlobalContext(): Omit<IGlobalContext, 'locale'> {
  if (typeof window === 'undefined') {
    return emptyContext
  }

  const globalContextElement = document.getElementById(GLOBAL_CONTEXT_ID)
  if (!globalContextElement) {
    return emptyContext
  }

  try {
    const globalContext = JSON.parse(globalContextElement.innerHTML)
    const slug =
      globalContext.locale === 'en'
        ? globalContext.slugEn
        : globalContext.slugEs

    return {
      ...globalContext,
      slug: slug || '',
    }
  } catch (error) {
    console.error('Error parsing shared context:', error)
    return emptyContext
  }
}
