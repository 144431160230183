import { ISnowplowPageContext } from 'app/components/Snowplow/Snowplow.interface'
import { Lang } from 'app/i18n'

export const GLOBAL_CONTEXT_ID = 'global-context'

export interface GlobalContextProps extends ISnowplowPageContext {
  slugEn: string | null
  slugEs: string | null
  contentId: string | null
  contentTypeId: string | null
}

export interface IGlobalContext extends ISnowplowPageContext {
  locale: Lang
  slug: string
  slugEn: string | null
  slugEs: string | null
  contentId: string | null
  contentTypeId: string | null
}
