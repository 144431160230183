import {
  Engagement,
  trackEngagement as trackSpEngagement,
} from '@generated/snowplow'
import { ISnowplowEngagementEvent } from './Snowplow.interface'
import getSharedContext, { trimPayload } from './getSharedContext'

export default function trackEngagement(event: ISnowplowEngagementEvent) {
  if (typeof window === 'undefined') {
    return
  }

  const engagementContext = trimPayload<Engagement>({
    event_type: event.eventType,
    platform: event.platform,
    submitted_text: event.submittedText,
    submitted_list: event.submittedList,
    submitted_object: event.submittedObject
      ? JSON.stringify(event.submittedObject)
      : undefined,
    submit_success: event.submitSuccess,
    percent_completed: event.percentCompleted,
    av_timestamp: event.avTimestamp,
    av_speed: event.avSpeed,
  })

  const sharedContext = getSharedContext({
    ...event,
    eventHorizontalPosition: window?.scrollX,
    eventVerticalPosition: window?.scrollY,
  })

  trackSpEngagement({
    ...engagementContext,
    ...sharedContext,
  })
}
