'use client'

import React, { HTMLProps } from 'react'
import Link from 'app/components/Link/Link.client'
import trackEngagement from 'app/components/Snowplow/trackEngagement'
import trackClick from 'app/components/Snowplow/trackClick'
import {
  ISnowplowClickEvent,
  ISnowplowEngagementEvent,
} from 'app/components/Snowplow/Snowplow.interface'

interface ClickProps {
  trackProps: ISnowplowClickEvent
  isEngagementEvent: false
}

interface EngagementProps {
  trackProps: ISnowplowEngagementEvent
  isEngagementEvent: true
}

type TrackProps = ClickProps | EngagementProps
type ComponentProps = Omit<
  HTMLProps<HTMLAnchorElement | HTMLButtonElement>,
  'as'
>

type TrackClickProps = {
  as?: React.ElementType
  children?: React.ReactNode
  [key: string]: any
} & TrackProps &
  ComponentProps

function TrackClick({
  as = Link,
  children,
  trackProps,
  isEngagementEvent = false,
  ...props
}: TrackClickProps) {
  const track = isEngagementEvent ? trackEngagement : trackClick

  return React.createElement(
    as,
    {
      ...props,
      onClick: (e) => {
        props?.onClick?.(e)
        track(trackProps as any)
      },
    },
    children
  )
}

export default TrackClick
