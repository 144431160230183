'use client'

import NextLink from 'next/link'
import { useParams } from 'next/navigation'
import React from 'react'
import { ILinkProps } from './Link.interface'

const formatHref = (href) => {
  const languageRegex = /\/(en\/|es-mx\/|en$|es-mx$)/
  const understoodDomainRegex = /^(understood.org|u.org|www.understood.org)/
  const httpsRegex = /^http(s)?:\/\//
  const isBlogRegex = /^(\/blog|.*\.org\/blog)/

  // remove http(s)
  let newHref = href.replace(httpsRegex, '')

  // if is undestood domain or starts with / (e.g: /articles/en/slug)
  // AND is not blog
  if (
    !newHref.match(isBlogRegex) &&
    (newHref.match(understoodDomainRegex) || newHref.match(/^\//))
  ) {
    // get locale, if exists. Then removes all "/" from it
    const locale = newHref.match(languageRegex)?.[0]?.replace(/\//g, '')
    // remove domain and language from url
    newHref = newHref
      .replace(understoodDomainRegex, '')
      .replace(languageRegex, '/')
      .replace(/\/+/g, '/')

    // when href is www.understood.org it ends up empty after the regex
    if (!newHref) newHref = '/'

    return {
      newHref,
      locale,
      isInternal: true,
    }
  }

  // if not understood domain, return the original href and isInternal false
  return {
    newHref: href,
    locale: undefined,
    isInternal: false,
  }
}

function Link({
  children,
  href,
  className = 'primary-link',
  ...props
}: React.PropsWithChildren<ILinkProps>) {
  const params = useParams()
  const lang = params?.lang || 'en'
  if (!href) {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production')
      console.error('Fix me: Link without an href')
    return children
  }

  // Nextlink also accept objects, if object used href.pathname
  const isHrefObject = typeof href === 'object'
  const { locale, newHref, isInternal } = formatHref(
    isHrefObject ? href.pathname : href
  )
  const localizedHref = `/${props.locale || locale || lang}/${newHref}`.replace(
    /\/+/g,
    '/'
  )

  if (isInternal) {
    return (
      <NextLink
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        className={className}
        prefetch={false}
        href={
          isHrefObject
            ? {
                ...href,
                pathname: localizedHref,
              }
            : localizedHref
        }
      >
        {children}
      </NextLink>
    )
  }

  return (
    <a
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      className={className}
      href={newHref}
    >
      {children}
    </a>
  )
}
export default Link
